// Importing default theme color object to mutate it
import defaultColors from '@elegantstack/solid-ui-theme/src/colors'

// Using tailwind preset colors
import colors from '@elegantstack/solid-ui-theme/src/color-preset'

export default {
  ...defaultColors,
  // Alpha (primary)
  alphaLighter: colors.ecoBlue[100],
  alphaLighter2: colors.ecoBlue[50],
  alphaLight: colors.ecoBlue[300],
  alpha: colors.ecoBlue[500],
  alphaDark: colors.ecoBlue[600],
  alphaDarker: colors.ecoBlue[800],
  // beta (secondary)
  betaLighter: colors.ecoGreen[100],
  betaLight: colors.ecoGreen[300],
  beta: colors.ecoGreen[500],
  betaDark: colors.ecoGreen[700],
  betaDarker: colors.ecoGreen[800]
}